<template>
  <section class="section-title">
    <h1 class="section-title__title">Попутные грузы по Черноземью</h1>
    <h2 class="section-title__subtitle">Лучший способ дешево доставить груз</h2>
  </section>

  <section v-if="isShowForms" class="section-form section--container">
    <div class="input-container">
      <label>
        Откуда
        <div class="input-container__select">
          <select v-model="cargoForm.from">
            <option v-for="option in cities" :value="option">{{ option }}</option>
          </select>
        </div>
      </label>
    </div>
    <div class="input-container">
      <label>
        Куда
        <div class="input-container__select">
          <select v-model="cargoForm.to">
            <option v-for="option in cities" :value="option">{{ option }}</option>
          </select>
        </div>
      </label>
    </div>
    <div class="input-container">
      <label>
        Когда
        <div class="input-container__input">
          <input v-model="cargoForm.when" type="date">
        </div>
      </label>
    </div>
  </section>

  <section v-if="isShowForms" class="section-subtitle">
    <h2>В какие габариты войдет ваш груз?</h2>
  </section>

  <section v-if="isShowForms" id="section-cargo-volume" class="section-form section--container">
    <div class="input-container">
      <label>
        Вес
        <div class="input-container__input">
          <input v-model="cargoForm.weight" type="number">
          <div class="input__suffix">
            Кг
          </div>
        </div>
      </label>
    </div>
    <div class="input-container">
      <label @click="onVolumeFieldExpand" v-click-outside="() => volumeInputFocus = false">
        Объём
        <div class="input-container__input">
          <span>
            {{ cargoForm.volume.x || '0' }}x{{ cargoForm.volume.y || '0' }}x{{ cargoForm.volume.z || '0' }}
            см
          </span>
          <div :class="{ 'input-volume--open': volumeInputFocus }" class="input-volume">
            <span>Ширина:</span>
            <input v-model="cargoForm.volume.x" type="number">
            <span>Высота:</span>
            <input v-model="cargoForm.volume.y" type="number">
            <span>Длина:</span>
            <input v-model="cargoForm.volume.z" type="number">
            <div class="input-container mobile-only">
              <button :disabled="!isVolumeFieldComplete" @click.stop="onVolumeFieldSubmit"
                class="input-container__btn">Готово</button>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div class="input-container input-container--btn-fix">
      <button :disabled="!isCargoFormComplete" @click="onShowPrice" class="input-container__btn">Показать
        цену</button>
    </div>
  </section>

  <section v-if="showPrice && isShowForms" class="section-best-price section--container">
    <hr>
    <h2>Лучшая цена</h2>

    <div class="best-price">
      <div v-if="price.price" class="label">Самый дешёвый</div>
      <div v-if="price.price" class="price">{{ price.price }} руб</div>
      <div class="intervals">{{ price.message }}</div>
    </div>

    <div v-if="isShowForms" class="input-container">
      <button @click="onAcceptPrice" class="input-container__btn">Догрузиться за эту цену</button>
    </div>
    <hr>
  </section>

  <section v-if="showFrom && isShowForms" class="section-form section--container section--end">
    <div class="input-container">
      <label>
        Как Вас зовут?
        <div class="input-container__input">
          <input type="text" v-model="contactForm.name">
        </div>
      </label>
    </div>
    <div class="input-container">
      <label>
        Как до Вас дозвониться?
        <div class="input-container__input">
          <div class="input__prefix">+7</div>
          <input type="text" v-model="contactForm.phone" maxlength="10">
        </div>
      </label>
    </div>
    <div class="input-container input-container--btn-fix">
      <button :disabled="!isContactFormComplete" class="input-container__btn" @click="onSubmit">Отправить</button>
    </div>
    <div class="form-description">
      После заполнения формы с Вами свяжется специалист и согласует детали догрузки и даты исполнения заявки
    </div>
  </section>

  <section v-if="isLoading" class="loading-spinner">
    <div class="spinner"></div>
  </section>

  <section v-if="isSubmited" class="section-submited">
    Спасибо за обращение! Скоро с Вами свяжется специалист.
  </section>

</template>

<script>
import { defineComponent, reactive, ref, computed } from 'vue'

const priceMap = [
  {
    weight: 50,
    x: 50,
    y: 50,
    z: 50,
    price: 1800,
    message: 'Выполняется 3 рейса в неделю'
  },
  {
    weight: 100,
    x: 80,
    y: 80,
    z: 80,
    price: 2400,
    message: 'Выполняется 3 рейса в неделю'
  },
  {
    weight: 200,
    x: 120,
    y: 120,
    z: 120,
    price: 2900,
    message: 'Выполняется 3 рейса в неделю'
  },
  {
    weight: 400,
    x: 200,
    y: 150,
    z: 100,
    price: 3900,
    message: 'Выполняется 3 рейса в неделю'
  },
  {
    default: true,
    message: 'Цену на данный вес и объём уточните по телефону'
  }
]

const getPrice = (weight, x, y, z) => {
  for (const price of priceMap) {
    if (
      !price.default &&
      (
        parseInt(weight) > price.weight ||
        parseInt(x) > price.x ||
        parseInt(y) > price.y ||
        parseInt(z) > price.z
      )
    ) continue

    return price
  }
}

export default defineComponent({
  setup() {
    const cargoForm = reactive({
      from: '',
      to: '',
      when: '',
      weight: '',

      volume: {
        x: '',
        y: '',
        z: '',
      }
    })

    const cities = [
      "Воронеж",
      "Белгород",
      "Борисоглебск",
      "Курск",
      "Липецк",
      "Лиски",
      "Старый Оскол",
      "Тамбов",
    ]

    const volumeInputFocus = ref(false)

    const isVolumeFieldComplete = computed(() => {
      const volume = parseInt(cargoForm.volume.x) * parseInt(cargoForm.volume.y) * parseInt(cargoForm.volume.z)
      return !Number.isNaN(volume) && volume > 0
    })

    const onVolumeFieldExpand = (event) => {
      console.log('dsad', event)
      volumeInputFocus.value = true
    }

    const onVolumeFieldSubmit = (event) => {
      console.log('fasf', event)
      volumeInputFocus.value = false
    }

    const isCargoFormComplete = computed(() => {
      if (!cargoForm.from) return false;
      if (!cargoForm.to) return false;
      if (!cargoForm.when) return false;
      if (cargoForm.weight === '') return false;
      if (!isVolumeFieldComplete.value) return false;
      return true
    })

    const showPrice = ref(false)
    const price = ref(null)
    const showFrom = ref(false)

    const onShowPrice = () => {
      price.value = getPrice(
        cargoForm.weight,
        cargoForm.volume.x,
        cargoForm.volume.y,
        cargoForm.volume.z
      )
      showPrice.value = true
    }

    const onAcceptPrice = () => {
      showFrom.value = true
    }

    const contactForm = reactive({
      name: '',
      phone: '',
    })

    const phoneRegex = /[0-9]{10}/g

    const isContactFormComplete = computed(() => {
      if (!contactForm.name) return false;
      if (!contactForm.phone.match(phoneRegex)) return false;
      return true
    })

    const isShowForms = ref(true)
    const isLoading = ref(false)
    const isSubmited = ref(false)

    const onSubmit = async () => {
      isShowForms.value = false
      isLoading.value = true

      const res = await fetch('/submit/', {
        body: JSON.stringify({
          from: cargoForm.from,
          to: cargoForm.to,
          when: cargoForm.when,
          weight: cargoForm.weight,
          volume: {
            x: cargoForm.volume.x,
            y: cargoForm.volume.y,
            z: cargoForm.volume.z,
          },
          price: price.value.price,
          name: contactForm.name,
          phone: `+7${contactForm.phone}`,
          accept: true,
        }),
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        }
      })

      isLoading.value = false

      if (res.status === 200) {
        isSubmited.value = true
      }
    }

    return {
      cargoForm,
      isCargoFormComplete,
      cities,
      isVolumeFieldComplete,
      volumeInputFocus,
      onVolumeFieldExpand,
      onVolumeFieldSubmit,
      onShowPrice,
      showPrice,
      price,
      onAcceptPrice,
      showFrom,
      contactForm,
      isContactFormComplete,
      onSubmit,
      isShowForms,
      isSubmited,
      isLoading,
    }
  }
})

</script>