import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)
app.directive('click-outside', {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
})
app.mount('main')


document.addEventListener('DOMContentLoaded', () => {
  const overlays = [
    '#about-overlay',
  ]

  overlays.forEach(selector => {
    const overlay = document.querySelector(selector)
    const close_btn = overlay.querySelector('.overlay__close')

    close_btn.addEventListener('click', () => {
      overlay.classList.remove('open')

      setTimeout(() => {
        overlay.classList.remove('visible')
      }, 1000)
    })
  })

  const overlay_links = {
    '#about-link': '#about-overlay'
  }

  Object.entries(overlay_links).forEach(([link, overlay]) => {
    const link_elem = document.querySelector(link)
    const overlay_elem = document.querySelector(overlay)

    link_elem.addEventListener('click', () => {
      overlay_elem.classList.add('visible')
      setTimeout(() => {
        overlay_elem.classList.add('open')
      }, 0)
    })
  })

})